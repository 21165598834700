import styles from "./contact.module.css";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7hnw69c",
        "template_lss67mm",
        form.current,
        "Ow-BpuAkiVN5Ts5LP"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          alert('Your email was sent successfully');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id={styles.contact}>
      <h1 id="cont" className={styles["contact-title"]}>
        Contact Me
      </h1>
      <span className={styles["contact-desc"]}>
        Thank you for considering my services for your event. Please fill out
        the form below to get in touch, request a booking, or make any
        inquiries. I'm here to help you create an unforgettable musical
        experience for your special occasion
      </span>
      <form
        action=""
        className={styles["contact-form"]}
        ref={form}
        onSubmit={sendEmail}
      >
        <input
          type="text"
          className={styles["name"]}
          placeholder="Your Name"
          name="your_name"
        />
        <input
          type="email"
          className={styles["email"]}
          placeholder= "Your Email"
          name="your_email"
        />
        <input
          type="number"
          className={styles["number"]}
          placeholder="Your Phone Number"
          name="number"
        />
        <input
          type="date"
          className={styles["event-date"]}
          placeholder="Date of Your Event"
          name="date"
        />
        <input
          type="text"
          className={styles["event-type"]}
          placeholder="Event Type: e.g., Wedding, Corporate Event, Private Party, etc"
          name="event"
        />
        <input
          type="text"
          className={styles["event-venue"]}
          placeholder="Location of Your Event"
          name="venue"
        />
        <textarea
          className={styles.inquiry}
          name="message"
          id=""
          cols="30"
          rows="10"
          placeholder="Share any specific details or requests for your event"
        ></textarea>
        <button className={styles["submit-btn"]}>Submit</button>
        <div className="links">
          <FaFacebookSquare className={styles["social-icon"]} />
          <FaInstagram className={styles["social-icon"]} />
        </div>
      </form>
    </section>
  );
}

export default Contact;
