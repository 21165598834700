import React from "react";
import partyBg from "../../assets/party-bg.png";
import styles from "./events.module.css";
import{FaCheck} from 'react-icons/fa'

function Events() {
  let iconStyles = { color: "gold", fontSize: "1.5em" };

  return (
    <section id={styles.events}>
      <h2 className={styles["event-header"]}>DJ services</h2>
      <div className={styles["event-bar"]}>
        <img src={partyBg} alt="" className={styles["event-image"]} />

        <div className={styles["skill-text"]}>
          <h3 className={styles["event-title"]}>We cover : </h3>
          <div className={styles["event-types"]}>
            <p><span><FaCheck style={iconStyles}/> </span> Nightclubs and Bars</p>
            <p><span><FaCheck style={iconStyles}/></span>  Engagement parties</p>
            <p><span><FaCheck style={iconStyles}/></span> Weddings parties</p>


            <p><span><FaCheck style={iconStyles}/></span>Corporate Events</p>
            <p><span><FaCheck style={iconStyles}/></span> Festivals</p>
            <p><span><FaCheck style={iconStyles}/></span>Graduation Parties</p>
            <p><span><FaCheck style={iconStyles}/></span>Baby Showers</p>
            <p><span><FaCheck style={iconStyles}/></span> House parties</p>
            <p><span><FaCheck style={iconStyles}/></span> Birthday Parties  ( Adults / kids )</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Events;
