import React from 'react'
import {AiOutlineSend} from 'react-icons/ai'
import styles from './planningPoint.module.css'


function PlanningPoints() {

    let iconStyles = { color: "white", fontSize: "1em", marginRight: "0.5em" };

    return (
     
          <section className={styles["performance-lists"]}>
             <p className={styles["performance-list"]}>
             <AiOutlineSend style={iconStyles} />
             Your event, your way! We specialize in tailoring every aspect to your unique vision, making your dreams a reality."
            </p>
  
            <p>
             <AiOutlineSend style={iconStyles} />
             Our meticulous planning and efficient coordination guarantee a stress-free event experience, leaving you to savor every moment.            </p>
  
            <p>
            <AiOutlineSend style={iconStyles}/>
            I can help you plan the music and entertainment schedule, ensuring it complements the overall theme and atmosphere.
            </p>
          
            <p>
            <AiOutlineSend style={iconStyles}/>
            Expect quick responses and excellent communication every step of the way. We're here to make your life easier            </p>

            <p>
            <AiOutlineSend style={iconStyles}/>
            I arrive well in advance to set up and conduct sound checks, ensuring everything runs smoothly.
            </p>
            <p>
            <AiOutlineSend style={iconStyles}/>
            My attire and presentation are always in line with the formality and theme of the event.

            </p>
          </section>
     
    )
  }

export default PlanningPoints
