import React from "react";
import styles from "./intro.module.css";
import bg from "../../assets/ready prof.png";
import { Link } from "react-scroll";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";

function Intro() {
  return (
    <section id="intro">
      <div className={styles["intro-content"]}>
        <span className={styles.hello}>Hello!! </span>
        <span className={styles["intro-txt"]}>
          I'm <span className={styles["intro-name"]}>DJ Banks</span>
          <br />
          Your Favorite DJ and Event Organizer
        </span>
        <p className={styles["intro-para"]}>
          DJ Banks, a Cameroonian-born super Sta
          <span className="star">
            <FaStar />
          </span>
          r DJ now based in Texas USA is a trained therapist,
          <br /> who leverages the power of music to influence attitudes and
          emotions since 2019.
          <br /> With him at the helm, join us on a journey of music that
          transcends borders <br /> and get ready to groove and be swept away by
          the beats!
        </p>
        <Link
          activeClass={styles.active}
          to="cont"
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
        >
          <button className={styles["intro-btn"]}>
            <span className={styles["intro-icon"]}>
              <BsFillBriefcaseFill />
            </span>
            book
          </button>
        </Link>
      </div>
      <img className={styles.bg} src={bg} alt="profile" />
    </section>
  );
}

export default Intro;
