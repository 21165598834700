import './App.css';
import Navbar from './components/navbar/Navbar';
import Intro from './components/intro/Intro';
import Services from './components/services/Services';
import Contact from './components/contact-me/Contact';
import Prices from './components/prices/Prices';

function App() {
  return (
    <div className="App">
   <Navbar />
<Intro />
<Services />
<Prices />
<Contact/>
    </div>
  );
}

export default App;
