import React from 'react'
import plan from '../../assets/plan.jpg'
import styles from './planning.module.css'

function Planning() {
  return (
   
       <section id={styles.events}>
      <h2 className={styles["event-header"]}> Event Planning</h2>
      <div className={styles["event-bar"]}>
        <img src ={plan} alt="" className={styles["event-image"]} />

        <div className={styles["skill-text"]}>
          <h3 className={styles["event-title"]}>Experience Unforgettable Events with Us</h3>
          <br />
          <div className={styles["event-types"]}>
        
            <p>From corporate gatherings to weddings, we've got your event planning and organization covered. When you choose us to plan your event, you're in for a treat - DJ Banks will be spinning live on the decks, and we're offering an exclusive discount! Book now and let's make your event truly spectacular</p>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Planning;
