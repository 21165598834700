import React from "react";
import styles from "./prices.module.css";

function Prices() {
  return (
    <div id='prices' className={styles.prices}>
        <h1 className={styles.pricing}>Pricing</h1>
        <hr className={styles.topLine}/>
        <p className={styles.info}>The pricing range is determined by factors such as the event location, size, and duration of service. Please contact us for a personalized quote tailored to your specific requirements.</p>
      <section className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Nightclubs and Bars</h4>
          </div>

          <div className={styles.price}>
            <p>$400</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Engagement parties </h4>
          </div>

          <div className={styles.price}>
            <p>$400-$1000</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Weddings parties</h4>
          </div>

          <div className={styles.price}>
            <p>$500-$1500</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Corporate Events</h4>
          </div>

          <div className={styles.price}>
            <p>$400-$800</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Festivals</h4>
          </div>

          <div className={styles.price}>
            <p>$1500-$2000</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Graduation Parties</h4>
          </div>

          <div className={styles.price}>
            <p>$400-$700</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Baby Showers</h4>
          </div>

          <div className={styles.price}>
            <p>$400-$1000</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>House parties</h4>
          </div>

          <div className={styles.price}>
            <p>$400-$1000</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h4>Birthday Parties</h4>
          </div>

          <div className={styles.price}>
            <p>$350-$1000</p>
          </div>
          <div className={styles.cardBody}>
            <hr />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Prices;
