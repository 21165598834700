import React from "react";
import styles from "./performance.module.css";
import { AiOutlineSend } from "react-icons/ai";

function Performance() {
  let iconStyles = { color: "white", fontSize: "1em", marginRight: "0.5em" };

  return (
    <section className={styles["performance-lists"]}>
      <p>
        <AiOutlineSend style={iconStyles} />I specialize in curating the perfect
        playlist to keep the dance floor alive and the energy high.
      </p>

      <p>
        <AiOutlineSend style={iconStyles} />I have an extensive music library
        spanning various genres and eras to cater to diverse tastes.
      </p>
      <p>
        <AiOutlineSend style={iconStyles} />
        My mixing and beat-matching skills ensure seamless transitions between
        tracks for a continuous party vibe.
      </p>

      <p>
        <AiOutlineSend style={iconStyles} />I provide professional advice on
        song selection, tempo, and special moments, such as the first dance or
        grand entrance
      </p>
      <p>
        <AiOutlineSend style={iconStyles} />I offer state-of-the-art sound
        equipment to ensure crystal-clear audio and enough power to fill any
        venue
      </p>
    </section>
  );
}

export default Performance;
