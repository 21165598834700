import React from "react";
import styles from "./services.module.css";
import Events from '../events/Events'
import Performance from "../performance-points/Performance";
import Planning from "../planing/Panning";
import PlanningPoints from "../planning-points/PlanningPoints";

function Services() {
  return (
    <section id='services' className={styles.services}>
      
      <span className={styles['skill-title']}>What i do</span>
      <div className={styles["skill-summary-container"]}>
      <span className={styles['skill-sumary']}>
        As a professional DJ, my passion is to create unforgettable musical
        experiences for any occasion. Whether you're planning a wedding,
        corporate event, private party, or any special gathering, I have the
        expertise and equipment to make it a memorable and exciting event.
      </span>
      </div>
   <Events />
   <Performance />
   <Planning/>
   <PlanningPoints/>
   
    </section>
   
  );
}

export default Services;
