import React, {useState} from "react";
import Logo from "../../assets/useLogo.png.jpg";
import { Link } from "react-scroll";
import { FaRegMessage } from "react-icons/fa6";
import styles from "./navbar.module.css";
import {CgMenuLeftAlt} from 'react-icons/cg'


function Navbar() {
const [showMenu, setShowMenu] = useState('')
const handleMobileLinks = ()=>{
setShowMenu(false)
}
const mobMenuHandler = ()=>{
setShowMenu(!showMenu)
}
  return (
 


    <nav className={styles.navbar}>
      <img src={Logo} alt="logo" className={styles.logo} />
      <div className={styles["desktop-menu"]}>
        <Link
          activeClass={styles.active}
          to="intro"
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["desktop-menu-list"]}
        >
          Home
        </Link>
        <Link
          activeClass={styles.active}
          to="services"
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["desktop-menu-list"]}
        >
          Services
        </Link>


        <Link
          activeClass={styles.active}
          to="prices"
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["desktop-menu-list"]}
        >
          Prices
        </Link>


        <Link
          activeClass={styles.active}
          to="cont" 
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["desktop-menu-list"]}
        >
          Contact
        </Link>
      </div>
      <button
        onClick={() => {
          document.getElementById("cont").scrollIntoView({behavior:'smooth' });
        }}
        className={styles["desktop-menu-btn"]}
      >
        <span className={styles["contact-icon"]}>
         
          <FaRegMessage />
        </span>
        contact me  
      </button>

      <CgMenuLeftAlt className={styles["hamburger-menu"]} onClick={mobMenuHandler} />
      <div className={styles["mobile-menu"]}  style={{display: showMenu? 'flex': 'none' }}>
        <Link
          activeClass={styles.active}
          to="intro"
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["mobile-menu-list"]}
onClick={handleMobileLinks}
        >
          Home
        </Link>
        <Link
          activeClass={styles.active}
          to="services"
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["mobile-menu-list"]}
          onClick={handleMobileLinks}
        >
          Services
        </Link>


        <Link
          activeClass={styles.active}
          to="prices"
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["mobile-menu-list"]}
          onClick={handleMobileLinks}
        >
          Prices
        </Link>


        <Link
          activeClass={styles.active}
          to="cont" 
          spy={true}
          smooth={true}
          offset={-100}
          duration={700}
          className={styles["mobile-menu-list"]}
          onClick={handleMobileLinks}
        >
          Contact
        </Link>
      </div>


    </nav>
  );
}

export default Navbar;
